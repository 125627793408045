const slugify = require('slugify')

exports.sluger = function sluger(val,prefix="")
{
  var find = ['++','#','w/','|','&','+']
  var replace = ['plusplus','sharp','with','-','-','-']
  var bulk = replaceBulk(val,find,replace)

  var retVal = slugify(bulk, {
    remove: undefined, 
    lower: true,     
    strict: true,   
  })

  //console.log(retVal)
  return prefix + retVal
}

function replaceBulk( str, findArray, replaceArray ){
  var i, regex = [], map = {}; 
  for( i=0; i<findArray.length; i++ ){ 
    regex.push( findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1') );
    map[findArray[i]] = replaceArray[i]; 
  }
  regex = regex.join('|');
  str = str.replace( new RegExp( regex, 'g' ), function(matched){
    return map[matched];
  });
  return str;
}