import React from "react"
import { Link } from "gatsby"
import { AiOutlineHome } from "react-icons/ai"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { sluger } from "../util/sluger"

class SlugTester extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      slugged: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSlugify = this.onSlugify.bind(this)
  }

  handleChange = e => {
    const value = e.target.value
    this.setState({
      title: value,
    })
  }

  onSlugify = e => {
    e.preventDefault()
    const slug = sluger(this.state.title)
    this.setState({
      slugged: slug,
    })
  }

  render() {
    return (
      <Layout className="page">
        <SEO title="Meek Code Slug Tester" />
        <div
          className="wrapper"
          style={{
            textAlign: "center",
          }}
        >
          <form onSubmit={this.onSlugify}>
            <h2>Meek Code Slug Tester</h2>
            <label>
              Input Title:
              <br />
              <input
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
              />
            </label>
            <br />
            <br />
            <button type="submit">slugify</button>
          </form>
          <div>Slug result : {this.state.slugged}</div>
          <br />
          <br />
          <br />
          <div>
            <Link to="/" className="button-block">
              <AiOutlineHome className="icon -left" />
              Go back to Home
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SlugTester
